<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="ml-4">
          <b-img class="logo-size" :src="imgLogo" alt="Divvy" />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="6" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title
            title-tag="h3"
            class="font-weight-bold mt-3 mb-5 text-center">
            Seu e-mail foi verificado com sucesso!
          </b-card-title>

          <b-card-text class="text-center mt-3">
            <b-link :to="{ name: 'login-profile' }">
              <span>Click aqui para efetuar login.</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->

      <!-- Left Text-->
      <background-card />

      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow, BLink, BCardText, BCardTitle, BImg } from 'bootstrap-vue'
import BackgroundCard from './template/BackgroundCard.vue'

export default {
  components: {
    BCol,
    BRow,
    BLink,
    BCardText,
    BCardTitle,
    BImg,
    BackgroundCard,
  },
  data: () => ({
    // eslint-disable-next-line global-require
    imgLogo: require('@/assets/images/logo/logo.png'),
  }),
  async mounted() {
    try {
      this.key = { c: this.$route.query.c }
      this.sendData = {
        c: decodeURI(this.key.c),
      }
      const response = await this.$http.post(
        '/api/services/app/Account/ActivateEmail',
        this.sendData,
      )
      const success = response.data.result
      if (success) {
        this.$router.push({ name: 'login' })
      }
    } catch (error) {
      this.errors = error.message
    }
  },
}
</script>

<style lang="scss">
.logo-size {
  width: 40px;
}
.img-login {
  background-image: url('~@/assets/images/pages/login/img-login-crop.png');
  background-position: right;
  background-size: auto auto;
  background-repeat: no-repeat;
}
.text-login-type-one {
  width: 400px !important;
  margin-left: auto;
  color: #fff;
  font-size: 26px;
  font-weight: 600;
}
.text-login-type-one-point-color {
  color: #f8ac18;
}
.text-login-type-two {
  margin-left: auto;
  color: #fff;
  font-size: 14px;
}
a.forgot-password {
  color: #5e5873;
}
@import '@core/scss/vue/pages/page-auth.scss';
</style>
